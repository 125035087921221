import { render, staticRenderFns } from "./WarrantComparison.vue?vue&type=template&id=080bc930&scoped=true&"
import script from "./WarrantComparison.js?vue&type=script&lang=js&"
export * from "./WarrantComparison.js?vue&type=script&lang=js&"
import style0 from "./WarrantComparison.vue?vue&type=style&index=0&id=080bc930&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080bc930",
  null
  
)

export default component.exports